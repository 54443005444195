export namespace PilotOperatorSearchActions {
    export class SearchPilotOrOperator {
        public static readonly type = "[PilotOperatorSearch] Search pilot or operator";

        constructor(public searchedNumber: string) {}
    }

    export class ClearPilotOperatorSearch {
        public static readonly type = "[PilotOperatorSearch] Clear pilot or operator";
    }

    export class SearchOperatorByLegacyHash {
        public static readonly type = "[PilotOperatorSearch] Search operator by legacy system hash";

        constructor(public hash: string) {}
    }

    export class SearchPilotByLegacyHash {
        public static readonly type = "[PilotOperatorSearch] Search pilot by legacy system hash";

        constructor(public hash: string) {}
    }
}
