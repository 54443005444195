import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { EMPTY, Observable, finalize } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { PilotOrOperatorSearchResult, PilotOrOperatorSearchResultError } from "./pilot-operaor-search.models";
import { PilotOperatorSearchApiService } from "./pilot-operator-search-api.service";
import { PilotOperatorSearchActions } from "./pilot-operator-search.actions";

export interface PilotOperatorSearchStateModel {
    isProcessing: boolean;
    error: PilotOrOperatorSearchResultError | undefined;
    pilotOrOperator: PilotOrOperatorSearchResult | undefined;
}

const defaultState: PilotOperatorSearchStateModel = {
    isProcessing: false,
    error: undefined,
    pilotOrOperator: undefined,
};

@State<PilotOperatorSearchStateModel>({
    name: "pilotOperatorSearch",
    defaults: defaultState,
})
@Injectable()
export class PilotOperatorSearchState {
    constructor(private readonly pilotOperatorSearchApiService: PilotOperatorSearchApiService) {}

    @Selector()
    public static isProcessing(state: PilotOperatorSearchStateModel): boolean {
        return state.isProcessing;
    }

    @Selector()
    public static error(state: PilotOperatorSearchStateModel): PilotOrOperatorSearchResultError | undefined {
        return state.error;
    }

    @Selector()
    public static pilotOrOperator(state: PilotOperatorSearchStateModel): PilotOrOperatorSearchResult | undefined {
        return state.pilotOrOperator;
    }

    @Action(PilotOperatorSearchActions.SearchPilotOrOperator, { cancelUncompleted: true })
    public searchPilotOrOperator(
        context: StateContext<PilotOperatorSearchStateModel>,
        action: PilotOperatorSearchActions.SearchPilotOrOperator
    ) {
        return this.manageSearchResult(context, this.pilotOperatorSearchApiService.searchPilotOrOperator(action.searchedNumber));
    }

    @Action(PilotOperatorSearchActions.ClearPilotOperatorSearch, { cancelUncompleted: true })
    public clearPilotOperatorSearch(context: StateContext<PilotOperatorSearchStateModel>) {
        context.patchState({
            pilotOrOperator: undefined,
            error: undefined,
        });

        return EMPTY;
    }

    @Action(PilotOperatorSearchActions.SearchOperatorByLegacyHash, { cancelUncompleted: true })
    public searchOperatorByLegacyHash(
        context: StateContext<PilotOperatorSearchStateModel>,
        action: PilotOperatorSearchActions.SearchOperatorByLegacyHash
    ) {
        return this.manageSearchResult(context, this.pilotOperatorSearchApiService.searchOperatorByLegacyHash(action.hash));
    }

    @Action(PilotOperatorSearchActions.SearchPilotByLegacyHash, { cancelUncompleted: true })
    public searchPilotByLegacyHash(
        context: StateContext<PilotOperatorSearchStateModel>,
        action: PilotOperatorSearchActions.SearchPilotByLegacyHash
    ) {
        return this.manageSearchResult(context, this.pilotOperatorSearchApiService.searchPilotByLegacyHash(action.hash));
    }

    private manageSearchResult(
        context: StateContext<PilotOperatorSearchStateModel>,
        searchResult$: Observable<PilotOrOperatorSearchResult>
    ) {
        context.patchState({
            isProcessing: true,
        });

        return searchResult$.pipe(
            tap((result: PilotOrOperatorSearchResult) => context.patchState({ pilotOrOperator: result, error: undefined })),
            catchError((error: PilotOrOperatorSearchResultError) => {
                context.patchState({ error, pilotOrOperator: undefined });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }
}
